import * as React from "react"
import { Container } from "@chakra-ui/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import Categories from "../components/categories"
import WorkDetailsContent from "../components/workDetailsContent"
import Statics from "../components/statistics"

const WorkDetails = () => (
  <Layout>
    <Breadcrumb
      description="Xord Solutions perceive blockchain as a source that constitutes infinite possibilities that exceed further from the mainstream use cases. We build blockchain products and give solutions along with the development of unique projects."
      title="Work Details"
      image="who-we-work-with-breadcrumb-banner.png"
    />
    <Container maxW={"full"} py="100px">
      <Categories />
      <WorkDetailsContent />
    </Container>
    <Statics />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default WorkDetails
