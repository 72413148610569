import React from "react"
import {
  Box,
  Text,
  Image,
  Tag,
  TagLabel,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react"

export default function WorkDetailsContent() {
  return (
    <Box>
      <Box py="50px">
        <Text as="h3" color="#04a073" fontWeight="600" fontSize="22px" mb="5">
          PARTNER
        </Text>
        <Tag size="lg" colorScheme="" borderRadius="full" p="0">
          <Image
            src="https://xord.solutions/wp-content/uploads/2022/06/Screenshot-from-2022-06-04-13-46-16-e1654506526805-300x140.png"
            name="Segun Adebayo"
            width="20px"
            mr="2"
          />
          <TagLabel fontSize="22px">LOKR</TagLabel>
        </Tag>
      </Box>
      <Box pb="25px">
        <Text as="p" fontSize="20px">
          Polkalokr serves as an escrow platform, where they lock the tokens
          until the product is delivered and verified. Polkalokr fosters
          innovative project development over credible chains, initially started
          out with Ethereum. Their products are there to help build trust in
          Web3 projects, especially in the Defi space by introducing solutions
          which will pave the way for decentralized governance adoption.
        </Text>
      </Box>
      <Box pb="35px">
        <Text as="h2" fontSize="40px" fontWeight="700">
          Polkalokr Bridgr
        </Text>
        <Text as="p" fontSize="20px">
          Blockchain technology has illustrated a great promise of utility over
          several fields including “Internet of Things” , finance, governance,
          identity management, web decentralization and asset-tracking but we
          have yet to see a single chain successfully achieve all with the
          ability to scale and not get stuck into the blockchain trilema.
          Interoperability allows different chains to collaborate and enable the
          users to benefit out of other chains in case of facing any blockers on
          a single chain . LKR Bridgr was created to safely migrate the LKR
          tokens to other networks. The vision was to make a cross-chain bridge
          which would support token migration on prominent chains and not limit
          the solution to just ethereum and Binance. Since Ethereum has a high
          gas price users would rather have their funds migrated to chains with
          low gas price as a standard for instance Binance and Ethereum L2
          solution - <br />
          <br /> Polygon chain The bridgr is interoperable between three famous
          chains - Ethereum, Binance and Polygon. Polkalokr also opened an
          opportunity for businesses having their tokens on the above-mentioned
          chains, they can deploy bridge contracts for their respective tokens
          using the bridge service enabled by polkalokr platform.
        </Text>
      </Box>
      <Box pt="25px">
        <Text as="h2" fontSize="40px" fontWeight="700">
          Bridge User Flow{" "}
        </Text>
        <Box pt="35px">
          <Text as="h3" color="#04a073" fontWeight="600" fontSize="22px" mb="5">
            1. Connect Wallet:
          </Text>
          <Text as="p" fontSize="20px">
            Decentralized applications expect the user to connect their account
            to the Dapp via wallet extension or wallet application .
          </Text>
          <Image
            py="15px"
            src="https://lh4.googleusercontent.com/B0a7z1uctaZQm-nK0Iegp5zVl6BjYMEl8itgczQU6BaNkwJyfQPRCYlw_W3qlBxukAPLgwYMV9eJQVUzqwuHgEbUyrao7ClYjpkfXrZu_d_Flgc-XI06XnhHjJpRpXzpL8IiVAiUL8M3LhZS3A"
          />
          <Text as="p" fontSize="20px">
            Blockchain technology has illustrated a great promise of utility
            over several fields including “Internet of Things” , finance,
            governance, identity management, web decentralization and
            asset-tracking but we have yet to see a single chain successfully
            achieve all with the ability to scale and not get stuck into the
            blockchain trilema.
          </Text>
          <Image
            py="15px"
            src="https://lh4.googleusercontent.com/SdEWdOrHm7a68cfxb3LI2hzeKHnVpD9oqwu6upfbJbFE-K7qzSPVed_dsiOWe2qjZsgXcTue8lUD9jyL67IzwOS9XebOhEzLVZk98c7r8xOif0eqMKpn6nLD0bHGOU7oD8TPWxbgPur_eoe7yg"
          />
        </Box>
        <Box pt="35px">
          <Text as="h3" color="#04a073" fontWeight="600" fontSize="22px" mb="5">
            2. Migration from primary chain to the secondary chain:
          </Text>
          <Text as="p" fontSize="20px">
            After connecting your wallet, now you have to select the chain from
            the dropdown to which you want to migrate your LKR tokens.
          </Text>
          <UnorderedList pl="15px">
            <ListItem>
              <Text as="p" fontSize="20px">
                You will be asked to give approval of the tokens to be first
                transferred to the bridge contract of the native chain.
              </Text>
              <Image
                py="15px"
                src="https://lh6.googleusercontent.com/oUKCv_3xgEN0mwSZRgOiIc9nH0mIQXsXu168pLLfph3iPVIDKo5Mhqq-_v1JYhCgPn7FD_u-9_dmIUW4dk83-6KriWB3lsLCbs4YRLgiTvz8rwumnmaa0FSk1AMmapd76906u3_hKEpChJsbYQ"
              />
            </ListItem>
            <ListItem>
              <Text as="p" fontSize="20px">
                You will be asked to give approval of the tokens to be first
                transferred to the bridge contract of the native chain.
              </Text>
              <Image
                py="15px"
                src="https://lh6.googleusercontent.com/oUKCv_3xgEN0mwSZRgOiIc9nH0mIQXsXu168pLLfph3iPVIDKo5Mhqq-_v1JYhCgPn7FD_u-9_dmIUW4dk83-6KriWB3lsLCbs4YRLgiTvz8rwumnmaa0FSk1AMmapd76906u3_hKEpChJsbYQ"
              />
            </ListItem>
            <ListItem>
              <Text as="p" fontSize="20px">
                You will be asked to give approval of the tokens to be first
                transferred to the bridge contract of the native chain.
              </Text>
              <Image
                py="15px"
                src="https://lh6.googleusercontent.com/oUKCv_3xgEN0mwSZRgOiIc9nH0mIQXsXu168pLLfph3iPVIDKo5Mhqq-_v1JYhCgPn7FD_u-9_dmIUW4dk83-6KriWB3lsLCbs4YRLgiTvz8rwumnmaa0FSk1AMmapd76906u3_hKEpChJsbYQ"
              />
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
      <Box pt="35px">
        <Text as="h2" fontSize="40px" fontWeight="700">
          Conclusion
        </Text>

        <Text as="p" fontSize="20px">
          Web3 space is all about creating a safe and decentralized space for
          users to engage and provide easy access to services in a decentralized
          capacity by ensuring security. Polkalokr enabled businesses and
          individuals to escape from the hassle of creating the bridge from
          scratch and benefit from the service being made available for all.
          Polkalokr will soon launch bridge as a service, which will open doors
          for many projects to safely deploy their bridge contracts for their
          respective tokens and benefit from the inherent security provided by
          the platform. After the launch of bridge as a service, more evm
          compatible chains support will be added eventually.
        </Text>
      </Box>
    </Box>
  )
}
