import React from "react"
import {
  Container,
  Flex,
  Box,
  Text,
  Image,
  HStack,
  Tag,
  TagLabel,
  WrapItem,
  Wrap,
} from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"

const CategorieItem = ({ text }) => {
  return (
    <WrapItem>
      <Tag
        size="lg"
        key="lg"
        borderRadius="full"
        variant="outline"
        color="grey"
        border="0.5px solid grey"
        boxShadow="none"
      >
        <TagLabel fontSize="20px" p="10px">
          {text}
        </TagLabel>
      </Tag>
    </WrapItem>
  )
}

export default function Categories() {
  return (
    <Box pt="20px">
      <Text as="h3" color="#04a073" fontSize="22px" mb="5" fontWeight="600">
        CATEGORIES
      </Text>
      <Wrap spacing={4}>
        <CategorieItem text="Ethereum App" />
        <CategorieItem text="Ethereum Layer 2 Solutions" />
        <CategorieItem text="DApp" />
      </Wrap>
    </Box>
  )
}
